
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';
import { EKSConfig } from '../types';
import { _EDIT } from '@shell/config/query-params';

import Checkbox from '@components/Form/Checkbox/Checkbox.vue';
import { removeObject } from '@shell/utils/array';

export default defineComponent({
  name: 'EKSLogging',

  components: { Checkbox },

  props: {
    config: {
      type:     Object as PropType<EKSConfig>,
      required: true
    },

    loggingTypes: {
      type:    Array,
      default: () => []
    },

    mode: {
      type:    String,
      default: _EDIT
    },
  },

  computed: { ...mapGetters({ t: 'i18n/t' }) },

  methods: {
    typeEnabled(type: string) {
      return this.loggingTypes.includes(type);
    },

    toggleType(type:string) {
      const out = [...this.loggingTypes];

      if (this.loggingTypes.includes(type)) {
        removeObject(out, type);
      } else {
        out.push(type);
      }

      this.$emit('update:loggingTypes', out);
    }
  },
});
